
.page-header {
    height: 50mm !important;
    position: fixed;
    top: 0mm;
    width: 100%;
}

.page-header-space {
    height: 60mm !important;
}

.page-footer, .page-footer-space {
    height: 160px;

}
.page-footer {

    position: fixed;
    bottom: 0;
    width: 100%;

}

.dataIN {
    vertical-align: bottom !important;
    margin-top: 14px !important;
    background-color: red;
}



#footerImg {
width: 100%;
    max-height: 100%;
    display: block;
}

#headerImg {
    width: 100%;
    max-height: 100%;
    padding-right: -119mm !important;
    object-fit: fill;
    width: 296mm;
    display: block;
}



.page {
    page-break-after: always;

}

@page {
    /* margin: 20mm; */
    size: A5;
}

@media print {
    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    button {
        display: none;
    }

    .body {
        margin: 0;
    }
}